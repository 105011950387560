import { get } from 'lodash'

export default function MapHovers({ onHover, onClick, highlights = [] }) {
  const getLink = (num) =>
    get(
      highlights.find((h) => get(h, 'highlightsid') === num),
      'parklink.uid'
    )

  return (
    <svg
      width="100%"
      viewBox="0 0 2896 4096"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        id="null"
        x="0"
        y="0"
        width="2896"
        height="4096"
        fill="transparent"
        onMouseOver={() => onHover(null)}
      />
      <path
        id="0"
        d="M614 3365L508 3091H85.9998V3204C85.9998 3262 51.9999 3444 29.9999 3523C7.99986 3602 120 3599 127 3597C134 3595 438.494 3507.19 533 3461C622 3417.5 616 3391.5 614 3365Z"
        fill="transparent"
        className={`${getLink(0) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(0)}
        onClick={() => getLink(0) && onClick(0)}
      />
      <path
        id="1"
        d="M769 3088L614 3365C625.5 3411.5 579 3438.17 561 3447.5C541.667 3456.5 532.399 3467 537.999 3499C543.599 3531 607.333 3572 653 3579L968 3544L1062 3478C1060.67 3466.33 1061.14 3428 1066.5 3392.5C1071.86 3357 1089.5 3208 1083.5 3171L1062 3136.5L769 3088Z"
        fill="transparent"
        className={`${getLink(1) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(1)}
        onClick={() => getLink(1) && onClick(1)}
      />
      <path
        id="2"
        d="M889.5 3598.5C953.333 3555.83 1081.4 3470.2 1083 3469L1102 3651C1056 3678 963.4 3732.6 961 3735C958.6 3737.4 912.333 3684.67 889.5 3658V3598.5Z"
        fill="transparent"
        className={`${getLink(2) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(2)}
        onClick={() => getLink(2) && onClick(2)}
      />
      <path
        id="3"
        d="M1063 3420.5C1064.5 3438.33 1067.2 3474.3 1066 3475.5L1516.5 3140.5V3027H1223L1082.5 3143.5C1084 3156.5 1086.9 3191.3 1086.5 3226.5C1086.1 3261.7 1070.67 3370.5 1063 3420.5Z"
        fill="transparent"
        className={`${getLink(3) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(3)}
        onClick={() => getLink(3) && onClick(3)}
      />
      <rect
        id="4"
        x="1432"
        y="3375"
        width="216"
        height="187"
        fill="transparent"
        className={`${getLink(4) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(4)}
        onClick={() => getLink(4) && onClick(4)}
      />
      <path
        id="5"
        d="M1791 3365L1646 3475.5C1644 3490 1640.1 3519.5 1640.5 3521.5C1640.9 3523.5 1659.67 3549.33 1669 3562L1880.5 3453C1878.33 3444.5 1873.3 3427.7 1870.5 3428.5C1867.7 3429.3 1816.33 3386.5 1791 3365Z"
        fill="transparent"
        className={`${getLink(5) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(5)}
        onClick={() => getLink(5) && onClick(5)}
      />
      <path
        id="6"
        d="M1616.5 3231.5C1617.17 3218 1618.7 3190.5 1619.5 3188.5L1730 3104.5L2060 2825.5C2108 2851.5 2204.1 2904 2204.5 2906C2204.9 2908 2191.33 2961.5 2184.5 2988L1778 3349.5H1744.5L1616.5 3231.5Z"
        fill="transparent"
        className={`${getLink(6) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(7)}
        onClick={() => getLink(6) && onClick(6)}
      />
      <path
        id="7"
        d="M1790 3363C1798 3358 1814.4 3347.6 1816 3346C1817.6 3344.4 1938.33 3364.67 1998.5 3375L2067 3121.5L2191.5 2981.5L2226.5 2911.5L2259 2930.5L2285.5 3237C2290.5 3306.83 2300.6 3447 2301 3449C2301.5 3451.49 2163 3774.66 2163.98 3777.98C2164.01 3778 2164.02 3778.01 2164 3778C2163.99 3778 2163.99 3777.99 2163.98 3777.98C2161.59 3775.99 2007.66 3668.3 1930 3614L1881 3451.5L1872 3429.5L1790 3363Z"
        fill="transparent"
        className={`${getLink(7) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(6)}
        onClick={() => getLink(7) && onClick(7)}
      />
      <path
        id="8"
        d="M2401.5 2842L2333.5 2980.5L2306 2955L2065.5 2825L2014 2141.5L2030.5 2066.5L2150.5 2122.5C2138.5 2165.33 2123.2 2259.8 2158 2295C2192.8 2330.2 2264.5 2413 2296 2450L2252 2538L2401.5 2842Z"
        fill="transparent"
        className={`${getLink(8) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(8)}
        onClick={() => getLink(8) && onClick(8)}
      />
      <path
        id="9"
        d="M2600.5 2594.5C2593.7 2655.3 2536.67 2678.83 2509 2683L2261 2524.5L2297.5 2449.5C2255.33 2399 2166.4 2292.5 2148 2270.5C2129.6 2248.5 2144 2166 2153.5 2127.5L2573 2132L2584.5 2305.5C2592.67 2376.5 2607.3 2533.7 2600.5 2594.5Z"
        fill="transparent"
        className={`${getLink(9) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(9)}
        onClick={() => getLink(9) && onClick(9)}
      />
      <path
        id="10"
        d="M2173 1370C2151.33 1372.67 2107 1378.4 2103 1380C2084 1385.5 2034 1402.1 1986 1424.5C1938 1446.9 1900.33 1518.83 1887.5 1552L1874.5 1619C1900.17 1664.33 1952.2 1754.9 1955 1754.5C1957.8 1754.1 2100.17 1845 2171 1890.5C2241.17 1861.5 2382 1803.2 2384 1802C2386 1800.8 2459.5 1676.83 2496 1615L2483.5 1499.5L2425 1451.5C2418.67 1454.83 2402.4 1459.5 2388 1451.5C2370 1441.5 2293 1380 2279 1376C2267.8 1372.8 2238.33 1377.33 2225 1380L2173 1370Z"
        fill="transparent"
        className={`${getLink(10) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(10)}
        onClick={() => getLink(10) && onClick(10)}
      />
      <path
        id="11"
        d="M2221.5 1381L2132.5 1357C2110 1341.83 2064.4 1311.1 2062 1309.5C2059 1307.5 1993.5 1133 1996 1134.5C1998 1135.7 1863.5 867 1796 732.5C1807.33 724.667 1848.7 704 1923.5 684C1998.3 664 2045.33 590.333 2059.5 556C2073 530.667 2101.1 477.6 2105.5 468C2109.9 458.4 2135.33 420 2147.5 402L2182.5 414.5C2236.17 485.5 2354.8 623.899 2400 609.499C2445.2 595.099 2634.5 674.166 2723.5 715.499C2735 757.832 2758.4 843.199 2760 845.999C2762 849.499 2667 1025 2678.5 1107.5C2689.88 1189.17 2573.86 1316.91 2572.99 1318.01C2573.02 1317.98 2573.02 1317.98 2573 1318C2572.98 1318.02 2572.98 1318.02 2572.99 1318.01C2571.05 1319.77 2438.47 1445.53 2436.5 1448C2434.5 1450.5 2418 1456 2395 1455.5C2372 1455 2359.5 1432 2344 1415.5C2328.5 1399 2286 1377.5 2275 1376C2266.2 1374.8 2235.67 1378.83 2221.5 1381Z"
        fill="transparent"
        className={`${getLink(11) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(11)}
        onClick={() => getLink(11) && onClick(11)}
      />
      <path
        id="12"
        d="M2130.5 419.501C2132.5 419.101 2082.67 509.668 2057.5 555.001C1986.17 562.001 1842.7 576.4 1839.5 578C1836.3 579.6 1790.5 522 1768 493L1748 353L1878.5 41.5L2148 298L2166 395.5C2153.33 403.667 2128.5 419.901 2130.5 419.501Z"
        fill="transparent"
        className={`${getLink(12) ? 'cursor-pointer' : ''}`}
        onMouseOver={() => onHover(12)}
        onClick={() => getLink(12) && onClick(12)}
      />
    </svg>
  )
}
